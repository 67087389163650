import { lightboxImages, paperOptions, showLightbox, showPaperOptions } from '@/stores';
import { useStore } from '@nanostores/react';

import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.locale('nb');

const MultiPaper = () => {
  const paper = useStore(paperOptions);
  const open = useStore(showPaperOptions);
  const close = () => showPaperOptions.set(false);

  if (!open) return null;
  const papers = [paper?.main_paper, ...paper?.other_papers];

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={close}>
      <div className="bg-white rounded-lg shadow-lg p-8  max-h-full overflow-y-auto relative flex gap-4">
        <button
          className="
        text-gray-500

        "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 absolute top-3 right-3 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={close}
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {papers.map((page, i) => {
          const realExpireDate = `${page.expires_at}`;

          const daysLeft = dayjs(realExpireDate).fromNow(true);
          return (
            <div
              key={page?.id + i}
              className="paper relative flex flex-col rounded-lg bg-[#F6F6F6] border-[#DEDEDE] border p-5 w-full items-center"
              onClick={() => {
                close();
                lightboxImages.set(page.pages.map((p) => ({ src: p.url })));
                showLightbox.set(true);
              }}
            >
              <img
                src={paper.logo_round === '/' ? 'https://kupp.no' + paper.logo_round : paper.logo_round}
                alt={`${paper.name} logo`}
                width="40"
                height="40"
                className="absolute z-10 top-3 left-3  w-8 h-8"
              />
              <div className="relative paper-pages w-28 h-40 cursor-pointer">
                {page.pages
                  ?.slice(0, 3)
                  .map((page, index) => (
                    <img
                      width="96"
                      height="119"
                      src={page.url}
                      alt={`Page ${index + 1}`}
                      className="absolute w-24"
                      style={{ zIndex: 5 - index, top: index * 7 + 'px', left: index * 7 + 'px' }}
                    />
                  ))}
              </div>
              <span className="self-start mt-3 font-bold text-lg">{page.title ?? 'Kundeavis'}</span>
              {page.expires_at && (
                <span className="self-start inline-flex text-[#777777] text-sm" title={realExpireDate}>
                  {daysLeft} igjen
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiPaper;
